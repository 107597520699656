import React, { FC } from 'react';
import styles from './ErrorIcon.module.css';
import { ERROR_REASON } from 'utils/constants';
import ReactTooltip from 'react-tooltip';
import ErrorReason from 'components/ErrorReason/ErrorReason';
import { Popover } from '@headlessui/react';
interface ErrorIconProps {
  errorReason?: string;
  id: string;
}

const ErrorIcon: FC<ErrorIconProps> = ({ errorReason, id, children }) => {
  if (!errorReason) {
    return null;
  }

  return (
    <div className="flex gap-x-1.6">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              data-for={id}
              data-tip=""
              className="w-2.4 h-2.4 bg-white rounded-full flex justify-center items-center shadow-primary shrink-0"
              as="div"
            >
              <ErrorReason errorReason={errorReason} className={styles.icon} />
            </Popover.Button>
            {!open && (
              <ReactTooltip
                id={id}
                place="bottom"
                effect="solid"
                className={`${styles.tooltip} shadow-tooltip`}
              >
                <span className="text-11 text-darkest-grey flex h-full items-center normal-case max-w-[150px]">
                  {errorReason === ERROR_REASON.DUPLICATE_BOOKING &&
                    'Duplicate booking'}
                  {errorReason === ERROR_REASON.PARTIAL_PATIENT_MATCH &&
                    'Partial patient match'}
                  {errorReason === ERROR_REASON.GENERAL_ERROR &&
                    'Uncategorized error. Contact FirstIn for support'}
                  {errorReason === ERROR_REASON.OUT_OF_OFFICE_HOURS &&
                    'Patient is scheduled outside office hours'}
                  {errorReason === ERROR_REASON.OUT_OF_PRACTITIONER_HOURS &&
                    'Patient is scheduled outside practitioner’s hours'}
                  {errorReason === ERROR_REASON.PATIENT_NEED_ADD_TO_FAMILY &&
                    'Please create a family file for this patient in Dentrix and wait 15 minutes prior to accepting this appointment'}
                  {errorReason === ERROR_REASON.WRITE_FAIL &&
                    'Failed to write to PMS'}
                  {errorReason ===
                    ERROR_REASON.UNFOUNDED_AVAILABLE_PRACTITIONER &&
                    'Unable to find an appropriate time slot for this patient'}
                </span>
              </ReactTooltip>
            )}
            <Popover.Panel className="absolute z-10" static>
              {children}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ErrorIcon;
