import { range } from 'lodash';
import { convertDurationForFE } from './convertDuration';
import { MINUTE_OF_A_BLOCK } from './getTimeBlockFromTImeString';

export const TOKEN_EXPIRED_MESSAGE = 'The token is expired';

export const APPOINTMENT_STATUS = {
  NEW: { KEY: 'new', LABEL: 'New' },
  ACCEPTED: { KEY: 'accepted', LABEL: 'Accept' },
  MISSED: { KEY: 'missed', LABEL: 'Miss' },
  COMPLETED: { KEY: 'completed', LABEL: 'Complete' },
  CANCELLED: { KEY: 'cancelled', LABEL: 'Cancel' },
  ERROR: { KEY: 'error', LABEL: 'Error' },
};

export const ERROR_REASON = {
  DUPLICATE_BOOKING: 'duplicate_booking',
  PARTIAL_PATIENT_MATCH: 'partial_patient_match',
  GENERAL_ERROR: 'general_error',
  OUT_OF_OFFICE_HOURS: 'out_of_office_hours',
  OUT_OF_PRACTITIONER_HOURS: 'out_of_practitioner_hours',
  PATIENT_NEED_ADD_TO_FAMILY: 'patient_need_add_to_family',
  WRITE_FAIL: 'write_fail',
  UNFOUNDED_AVAILABLE_PRACTITIONER: 'unfounded_available_practitioner',
};

export const FIRSTIN_SYSTEM = 'firstin';

export const DAYS_OF_WEEK = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const STATUS = { ACCEPT: 'Accept' as const, REJECT: 'Reject' as const };

export type ErrorAppointmentStatusType =
  | 'success'
  | 'error'
  | 'duplicate'
  | 'already_resolved'
  | 'timeslot_not_available'
  | 'out_of_office_hours'
  | 'out_of_practitioner_hours'
  | 'general_error'
  | 'unfounded_available_practitioner'
  | 'null_operatory';

export const ERROR_APPOINTMENT_STATUS = {
  SUCCESS: 'success' as const,
  ERROR: 'error' as const,
  DUPLICATE: 'duplicate' as const,
  ALREADY_RESOLVED: 'already_resolved' as const,
  TIMESLOT_NOT_AVAILABLE: 'timeslot_not_available' as const,
  OUT_OF_OFFICE_HOURS: 'out_of_office_hours' as const,
  OUT_OF_PRACTITIONER_HOURS: 'out_of_practitioner_hours' as const,
  GENERAL_ERROR: 'general_error' as const,
  UNFOUNDED_AVAILABLE_PRACTITIONER: 'unfounded_available_practitioner' as const,
  NULL_OPERATORY: 'null_operatory' as const,
};

export const ERROR_RESPONSE_SERVER = {
  ALREADY_RESOLVED_MESSAGE: 'Appointment is resolved successfully' as const,
  TIMESLOT_NOT_AVAILABLE_MESSAGE: 'Time slot is not available' as const,
  DUPLICATE_BOOKING: 'duplicate_booking' as const,
  APPOINTMENT_NOT_SYNC_WITH_PMS: 'Appointment is not sync with PMS' as const,
};

export const SUPPORT_EMAIL = 'hello@firstin.io';

export const ROLE = {
  GLOBAL_ADMIN: 'global_admin',
  PRACTICE_ADMIN: 'practice_admin',
  PRACTICE_USER: 'practice_user',
};

export const CLINIC_USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
};

export const SYNC_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  IN_PROGRESS: 'in_progress',
};

export const SCHEDULE_TYPE = {
  GOOGLE: 'googleWorkingHour',
  DEFAULT: 'defaultWorkingHour',
};

export const WORKING_HOUR_SYSTEM = {
  GOOGLE: 'google',
  FIRSTIN: 'firstin',
};

export const DEFAULT_WORKING_HOUR = {
  START: '08:00:00',
  END: '17:00:00',
};

export const TIME_GRID_WORKING_HOUR = {
  START: '01:00:00',
  END: '24:00:00',
};

export const PRACTITIONER_FILTER_OPTIONS = {
  ALL: 'All',
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
};

export const PRACTICE_FILTER_OPTIONS = {
  ALL: 'All',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const WORKING_HOUR_EXCEPTION = {
  CLOSED: 'Closed',
  HOLIDAY: 'Holiday',
};

export const CUSTOM_HOURS = 'Custom Hours';

export const DAY_OFF = 'Day Off';

export const OFFICE_CLOSED = 'Office Closed';

export const NEXT_23_MONTH = '23';

export const DEFAULT_COUNTRY_CODE = 'ca';

export const PMS_TYPE = {
  DENTRIX: 'dentrix',
  FIRST_IN: 'firstin',
  NEX_HEALTH: 'nexhealth',
};

export const EXAM_CLEANING_DURATION_OPTIONS = range(
  2,
  125 / MINUTE_OF_A_BLOCK, // 125 minutes
  2
).map((value) => convertDurationForFE(value));

export const DURATION_OPTIONS = range(2, 190 / MINUTE_OF_A_BLOCK, 2).map(
  (value) => convertDurationForFE(value)
);

export const COLOR_CODES = [
  '#B01BAE',
  '#4D97E1',
  '#1A9901',
  '#867BEB',
  '#47B2AD',
  '#EAA51F',
  '#DC5C89',
  '#51169D',
  '#FF544A',
  '#32ABCA',
  '#D2B303',
  '#22668D',
  '#FF3FA4',
  '#A4907C',
  '#00CFFD',
  '#C87A9E',
  '#57375D',
  '#DF7358',
  '#57375D',
  '#5C8374',
  '#E9B384',
  '#A2C579',
  '#6C9BCF',
  '#A96D4B',
  '#654E92',
  '#016A70',
  '#9A3B3B',
  '#51698D',
  '#73AC97',
  '#C78AFE',
];

export const BACKGROUND_COLOR_CODES = [
  '#FFF6FF',
  '#EEFBFB',
  '#F7FFF2',
  '#F9F9FF',
  '#EDFCFC',
  '#FFF7E9',
  '#FFF3F7',
  '#F7F1FF',
  '#FFF3F7',
  '#F2FCFF',
  '#FEFBEB',
  '#F0FAFF',
  '#FFF2F9',
  '#FAF4EF',
  '#F0FCFF',
  '#FFF6FB',
  '#FCEFFF',
  '#FFF4F1',
  '#EAFAF4',
  '#EEF4FF',
  '#FFF5EC',
  '#F5FFEA',
  '#F5FAFF',
  '#F8EDE7',
  '#F4EEFF',
  '#ECFEFF',
  '#FFEDED',
  '#F2F7FF',
  '#F4FFFB',
  '#FBF8FF',
];

export const EVENT_COLOR_CODE = '#A9A9A9';
export const EVENT_BACKGROUND_COLOR_CODE = '#E0E0E3';

export const TIMEGRID_VIEW = {
  PRACTITIONER: 'practitioner',
  CHAIR: 'chair',
};

export const PMS_EVENT_TYPE = {
  OFFICE_CLOSED_HOUR: 'office_closed_hour',
  OFFICE_HOLIDAY: 'office_holiday',
  EVENT: 'event', // lunch event
  PMS_PRACTITIONER_HOURS: 'pms_practitioner_hours',
  AVAILABLE_EVENT: 'available_event',
  BLOCKED: 'blocked', // used for grey out cells if operatories are not assigned to doctor
  BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING:
    'blocked_doctor_hovering_exam_cleaning',
};

export const ERROR_REASON_MESSAGE = {
  [ERROR_REASON.DUPLICATE_BOOKING]: 'Duplicate appointment error',
  [ERROR_REASON.PARTIAL_PATIENT_MATCH]: 'Partial patient match error',
  [ERROR_REASON.OUT_OF_OFFICE_HOURS]: 'Outside office hours error',
  [ERROR_REASON.OUT_OF_PRACTITIONER_HOURS]: 'Outside practitioner hours error',
  [ERROR_REASON.GENERAL_ERROR]: 'Uncategorized error',
  [ERROR_REASON.PATIENT_NEED_ADD_TO_FAMILY]:
    'Patient needs to be added to a family file',
  [ERROR_REASON.WRITE_FAIL]: 'Failed to write to PMS',
  [ERROR_REASON.UNFOUNDED_AVAILABLE_PRACTITIONER]:
    'Unable to find an appropriate time slot for this patient',
};

export const SERVICE_NAME = {
  EXAM_CLEANING: 'Exam & Cleaning',
};

export const BOOKING_TYPE = {
  SMART_LINK: 'smart_link',
  SIGN_IN: 'sign_in',
  NON_SIGN_IN: 'non_sign_in',
};

export const SPECIALTY_NAME = {
  HYGIENIST: 'Hygienist',
  DENTIST: 'Dentist',
  BOTH: 'Both',
};

export const EXAM_PLACEMENT = {
  HEAD: 'head',
  TAIL: 'tail',
};

export const MAX_HYGIENIST_DURATION = 120;

export const MAX_OTHER_SERVICES_DURATION = 180;

export const MAX_DOCTOR_DURATION = 90;

export const TIME_DURATION_OPTIONS = [5, 10, 15, 20, 30];

export const INTERVAL_OPTIONS_PER_UNIT_DURATION = {
  5: [5, 10, 15, 20, 30],
  10: [10, 20, 30],
  15: [15, 30],
  20: [20],
  30: [30],
};
